import React, { useEffect, useState } from "react";

import MomentUtils from "@date-io/moment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { AxiosError } from "axios";
import { navigate } from "gatsby";
import * as moment from "moment";
import * as momentime from "moment-timezone";
import Swal from "sweetalert2";

import LoadingError from "~/components/Loaders/LoadingError";
import appointmentService from "~/utils/api/v1/appointmentService";
import NurseAPI from "~/utils/api/v2/nurse";
import { getAllCountryTimeblocks } from "~/utils/api/v2/timeblocks";
import { COUNTRY_TO_CODE, DEFAULT_TIMEZONE } from "~/utils/data/constants";
import { DetailedAppointment } from "~/utils/interfaces/Appointment";
import { TimeBlock } from "~/utils/interfaces/Timeblock";

moment.locale("es");

interface Props {
  id: string;
  onBack: () => void;
}

const SuperadminReschedule = (props: Props): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [updatingAppointment, setUpdatingAppointment] = useState<boolean>(false);
  const [error, setError] = useState<AxiosError | {}>({});
  const [date, setDate] = useState<moment.Moment>(moment());
  //! Type this three states
  const [appointmentData, setAppointmentData] = useState<DetailedAppointment | undefined>(undefined);
  const [timeblocks, setTimeblocks] = useState<TimeBlock[]>([]);
  const [selectedTimeblock, setSelectedTimeblock] = useState<string>("09:00:00-09:30:00");
  const [timezone, setTimezone] = useState<"America/Santiago" | "America/Mexico_City">("America/Santiago");

  const fetchData = async () => {
    // fetchs the appointment data (for previous date and time info)
    // and the timeblocks
    setLoading(true);
    try {
      const responseAppointments = await appointmentService.fetchAppointment(props.id);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const timeblocks = await getAllCountryTimeblocks(COUNTRY_TO_CODE[responseAppointments.data.country]);
      setAppointmentData(responseAppointments.data);
      setTimezone(responseAppointments.data.safe_timezone);
      setTimeblocks(timeblocks || []);
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  };

  const updateAppointment = async (): Promise<void> => {
    setUpdatingAppointment(true);
    try {
      const tb = selectedTimeblock.split("-");
      const begin = momentime.tz(`${date.format("YYYY-MM-DD")} ${tb[0]}`, timezone);
      const end = momentime.tz(`${date.format("YYYY-MM-DD")} ${tb[1]}`, timezone);

      const formattedBegin = begin.tz(timezone).format();
      const res = await NurseAPI.checkAvailable(props.id, formattedBegin);
      if (!res.available) {
        const proceedAnyways = await Swal.fire({
          title: "Este bloque no se encuentra disponible. Continuar de todas maneras?",
          icon: "warning",
          showCancelButton: true,
        });

        if (!proceedAnyways.isConfirmed) {
          return;
        }
      }

      const req = await appointmentService.rescheduleAppointment(props.id, {
        begin_date: `${begin.tz(timezone).format()}`,
        work_period_max_lateness: `${end.tz(timezone).format()}`,
      });
      const notif = await Swal.fire({
        icon: "success",
        title: "Cita re-agendada!",
        showCloseButton: false,
      });
      if (notif.isConfirmed || notif.isDismissed) {
        navigate(`/appointment/${props.id}/`);
      }
    } catch (err) {
      setError(err);
    }
    setUpdatingAppointment(false);
  };

  const displayTimeblockOptions = (): JSX.Element[] => {
    const filteredTimeblocks = timeblocks.filter((timeblck) => timeblck.day === "monday");
    return filteredTimeblocks.map((tblk: TimeBlock) => {
      return (
        <MenuItem
          key={tblk.id}
          value={`${tblk.begin_hour}-${tblk.end_hour}`}
        >
          {" "}
          {tblk.begin_hour} - {tblk.end_hour}{" "}
        </MenuItem>
      );
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <LoadingError
        loading={loading}
        error={error}
      />
      {!loading && (
        <div className="bg-whitesmoke p-20 rounded-lg flex flex-col items-center">
          <h1>
            Re-agendar <i>appointment</i>
          </h1>

          <div className="my-4">
            <span className="font-bold">Fecha anterior: </span> {appointmentData?.displayable_begin_date || ""}
          </div>

          <div className="flex items-center">
            <FormControl className="mx-4">
              <MuiPickersUtilsProvider
                utils={MomentUtils}
                locale="es"
              >
                <KeyboardDatePicker
                  disabled={updatingAppointment}
                  variant="inline"
                  format="LL"
                  margin="normal"
                  label="Fecha inicio"
                  value={date}
                  autoOk
                  onChange={(date: moment.Moment) => {setDate(date)}}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
            <FormControl className="mx-4">
              <InputLabel> Hora </InputLabel>
              <Select
                disabled={updatingAppointment}
                value={selectedTimeblock}
                onChange={(e) => {
                  setSelectedTimeblock(e.target.value as string);
                }}
              >
                {displayTimeblockOptions()}
              </Select>
            </FormControl>
          </div>

          <p>La zona horaria de este appointment es {appointmentData?.safe_timezone || DEFAULT_TIMEZONE}</p>

          <div className="flex items-center gap-x-8 mt-10">
            <button
              className="border-[1.5px] border-red-700 text-red-500 px-4 py-2 rounded hover:bg-red-500/10 transition uppercase"
              onClick={props.onBack}
              disabled={updatingAppointment}
            >
              Volver
            </button>
            <button
              className="border-[1.5px] border-blue-500 text-blue-500 px-4 py-2 rounded hover:bg-blue-500/10 transition uppercase"
              onClick={updateAppointment}
              disabled={updatingAppointment}
            >
              Aceptar
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default SuperadminReschedule;

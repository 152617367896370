import { AxiosResponse } from "axios";
import { decamelizeKeys } from "humps";
import qs from "qs";

import api from "~/utils/api/api";
import { parseError } from "~/utils/api/parseError";
import Appointment, { AppointmentV2, UpdateAppointment } from "~/utils/interfaces/Appointment";
import { AppointmentPatientV2 } from "~/utils/interfaces/AppointmentPatient";
import { CartPriceResponse } from "~/utils/interfaces/cartPrice";
import { NursePayment } from "~/utils/interfaces/nursePayments";
import { RequestError, RequestResponse } from "~/utils/interfaces/request";

class AppointmentAPI {
  get = async (id: string) => {
    return api.get<AppointmentV2>(`v2/appointments/${id}/`);
  };

  patch = async (id: string, data: any): Promise<AxiosResponse<any>> => {
    return api.patch(`v2/appointments/${id}/`, data);
  };

  listComments = async (id: string): Promise<AxiosResponse<any>> => {
    return api.get(`v2/appointments/${id}/comments/`);
  };

  createComment = async (id: string, data: object): Promise<AxiosResponse<any>> => {
    return api.post(`v2/appointments/${id}/comments/`, decamelizeKeys(data));
  };

  appointmentPatients = async (id: string) => {
    return api.get<AppointmentPatientV2[]>(`v2/appointments/${id}/appointment-patients/`);
  };

  appointmentPatientsPaginated = async (id: string, page = 1): Promise<AxiosResponse<any>> => {
    return api.get(`v2/appointments/${id}/paginated-appointment-patients/?page=${page}`);
  };

  sendOMReady = (data: { appointmentId: string }): any => {
    return api.post(`v2/deal-notifications/om-ready/`, decamelizeKeys(data));
  };

  registerEvent = async (id: string, event: string): Promise<AppointmentV2 | undefined> => {
    try {
      const res = await api.post<AppointmentV2>(`v2/appointments/${id}/events/?${qs.stringify({ event })}`);
      return res.data;
    } catch (error) {
      return undefined;
    }
  };
}

export const setCouponCodeToAppointment = async (
  appointment_id: string,
  code: string,
): Promise<AppointmentV2 | undefined> => {
  try {
    const res = await api.post<AppointmentV2>(`v2/appointments/${appointment_id}/referrals/`, {
      referral_code: code,
    });
    return res.data;
  } catch (error) {
    return undefined;
  }
};

export const addOperativeAppointmentExtraFiles = async (
  appointmentId: string,
  data: FormData,
): Promise<AxiosResponse> => {
  const res = await api.post(`v2/appointments/${appointmentId}/massive-results/`, data);
  return res;
};

export const notifyResultsReadyWhatsapp = async (appointmentId: string) => {
  const res = await api.post(`v2/appointments/${appointmentId}/notify-results-whatsapp/`);
  return res;
};

export const getAppointmentNursePayments = async (
  appointmentId: string,
): Promise<NursePayment[] | { error: string }> => {
  try {
    const res = await api.get<NursePayment[]>(`v2/appointments/${appointmentId}/nurse-payments/`);
    return res.data;
  } catch (error) {
    return parseError(error);
  }
};

export async function rescheduleAppointment({
  appointmentId,
  data,
}: UpdateAppointment): Promise<RequestResponse<Appointment>> {
  try {
    const res = await api.post<Appointment>(`/v2/appointments/${appointmentId}/reschedule/`, decamelizeKeys(data));
    return { data: res.data };
  } catch (error) {
    return { error } as RequestError;
  }
}

export async function registerNonSilentAppointment({
  appointmentId,
  reason,
}: {
  appointmentId: string;
  reason: string;
}) {
  const res = await api.post(`/v2/non-silent-appointments/`, decamelizeKeys({ appointmentId, reason }));
  return res.status;
}

export async function getTotalCartPrice(appointmentId: string): Promise<CartPriceResponse> {
  const res = await api.get<CartPriceResponse>(`/v2/appointments/${appointmentId}/pending/`);
  return res.data;
}

const appointmentAPI = new AppointmentAPI();
export default appointmentAPI;
